
  <app-banner fxHide.xs></app-banner>
  <app-banner-m fxHide.gt-xs></app-banner-m>
  <app-we-are></app-we-are>
  <app-to-do></app-to-do>
  <app-digitize fxHide.xs fxHide.sm></app-digitize>
  <app-digitize-m fxHide.gt-sm></app-digitize-m>
  <app-do-we-do-it fxHide.xs></app-do-we-do-it>
  <app-do-we-do-it-m fxHide.gt-xs></app-do-we-do-it-m>
  <app-presence></app-presence>
  <app-alliances-certifications></app-alliances-certifications>
  <!-- <app-form></app-form> -->
  <app-loading *ngIf="showLoad"></app-loading>
