<mat-nav-list fxFlex="100" fxLayout="column" fxLayoutAlign="space-between stretch">
  <mat-list fxLayout="column" fxFlex="100">
    <mat-list-item fxLayoutAlign="start center">
      <a [routerLink]="['home']" (click)="onSidenavClose()">{{ 'LandingPolimentes.navbar.inicio' | translate }}</a>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item fxLayoutAlign="start center">
      <a [routerLink]="['home']" [fragment]="'us'" (click)="onSidenavClose()">{{ 'LandingPolimentes.navbar.nosotros' | translate }}</a>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item fxLayoutAlign="start center">
      <a [routerLink]="['home']" [fragment]="'what-do-we-do'" (click)="onSidenavClose()">{{ 'LandingPolimentes.navbar.hacemos' | translate }}</a>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item fxLayoutAlign="start center">
      <a [routerLink]="['home']" [fragment]="'hacemos-m'" (click)="onSidenavClose()">{{ 'LandingPolimentes.navbar.como' | translate }}</a>
    </mat-list-item>
    <mat-divider></mat-divider>
    <!-- <mat-list-item fxLayoutAlign="start center">
      <a [routerLink]="['home']" [fragment]="'form'" (click)="onSidenavClose()">{{ 'LandingPolimentes.navbar.contacto' | translate }}</a>
    </mat-list-item> -->
    <mat-divider></mat-divider>
    <mat-list-item fxLayoutAlign="start center">
      <a [routerLink]="['join-up']" (click)="onSidenavClose()">{{ 'LandingPolimentes.navbar.unete' | translate }}</a>
    </mat-list-item>
  </mat-list>
  <mat-list fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center" >
    <img class="img-redes" src="/assets/icons/facebook1.svg" (click)="redesOpen('face')">
    <img class="img-redes" src="/assets/icons/insta1.svg" (click)="redesOpen('insta')">
    <img class="img-redes" src="/assets/icons/linkend1.svg" (click)="redesOpen('link')">
  </mat-list>
</mat-nav-list>
