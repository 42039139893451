import { ViewportScroller } from '@angular/common';
import { AfterContentChecked, AfterViewChecked, Component, OnInit } from '@angular/core';

import { ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as AOS from 'aos';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, AfterViewChecked {
  public showLoad: boolean = false;

  constructor(private route: ActivatedRoute, private scroller: ViewportScroller) {

  }

  ngOnInit(): void {
    // this.showLoad=true;
    AOS.init({
      duration: 2000
    });
  }

  ngAfterViewChecked(): void {
    // setTimeout(() => {
    //   this.showLoad = false;
    // }, 3000);
  }

}
