<mat-sidenav-container>
  <mat-sidenav #sidenav position='end' class="menuMovil" [fixedInViewport]="true" [fixedTopGap]="0" [fixedBottomGap]="0">
      <mat-toolbar>
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-around">
              <div fxFlex="80" fxLayout="row" fxLayoutAlign="start center">
                <img class="logo" src="/assets/img/logo_nav.png">
              </div>
              <div fxFlex="20" fxLayout="row" fxLayoutAlign="end center">
                  <img src="/assets/icons/close.svg"  (click)="sidenav.close()">
              </div>
          </div>
      </mat-toolbar>
      <app-navbar-list (sidenavClose)="sidenav.close()"></app-navbar-list>
  </mat-sidenav>
  <mat-sidenav-content>
      <main  fxFlex="100" fxLayout="column" fxLayoutAlign="space-between stretch">
          <app-navbar fxFlex="100" (sidenavToggle)="sidenav.toggle()"></app-navbar>
          <router-outlet fxFlex="100" ></router-outlet>
          <app-footer fxFlex="100"></app-footer>
      </main>
  </mat-sidenav-content>
</mat-sidenav-container>
