import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalUrl } from 'src/app/models/url.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }

  public sendContact(data: any): Observable<any> {
    console.log("Info enviada",data)
    return this.http.post(GlobalUrl.URL_Contacto_Polimentes + 'formulario_polimentes/v1/DejProNueMan/post/', data);
  }
  // public sendContact(data: any): Observable<any> {
  //   console.log("Info enviada",data)
  //   return this.http.post(GlobalUrl.URL_Contacto_Polimentes + 'landing/makecontact/', data);
  // }
  public sendJoin(data: any): Observable<any> {
    console.log("Info enviada",data)
    return this.http.post(GlobalUrl.URL_Contacto_Polimentes + 'formulario_polimentes/v1/UneNos/post/', data);
  }

  public datos(){
    return this.http.get('https://ipapi.co/json/');
  }
}
