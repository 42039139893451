export class GlobalUrl {
    public static URL_Produccion_Polipay = 'https://spp.polipay.mx/'; //Produccion Polipay
    public static URL_Contacto_Polimentes = 'https://stage-sia-p1801.polimentes.com/';
    // public static URL_Contacto_Polimentes = 'https://tester-sia-p1801.polimentes.com/';
    // public static URL_Contacto_Polimentes = 'http://192.168.68.150:8900/';
    public static PaginaFacebook = 'https://www.facebook.com/Polimentes';
    public static PaginaInstagram = 'https://www.instagram.com/polimentes_/';
    public static PaginaLinkelin = 'https://www.linkedin.com/company/polimentes/';
    public static KeyCapchat_Polipay = '6LfvYK4eAAAAAMaJuvqRY4QO7NHkOgZdZc9uF16g';
    public static Telefono_Polipay_What = '+5213329151681';
    public static Mensaje_Polipay_What = 'Hola%20Polipay.';
   }