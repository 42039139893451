<section class="fondo" id="us" [id]="'us'" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
  <div fxFlex="60" fxFlex.xs="85" fxFlex.sm="95" fxFlex.md="90" fxLayout="row wrap" fxLayoutAlign="space-around center">
    <div data-aos="fade-right" fxFlex="40" fxFlex.xs="100" fxLayout="row wrap" class="nosotros"
      fxLayoutAlign="center center">
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
        <span class="titulo-n">{{ 'LandingPolimentes.we-are.nosotros' | translate }}</span>
      </div>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
        <span class="text-n">Somos una empresa especializada en el desarrollo e implementación
          de soluciones tecnológicas a nivel global. Ayudamos a las empresas
          a alcanzar su máximo potencial a través de la digitalización de sus productos y servicios.</span>
      </div>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutAlign.xs="start start">
        <button class="button-n" [routerLink]="['/us']">{{ 'LandingPolimentes.we-are.conoce' | translate }}</button>
      </div>
    </div>
    <div data-aos="fade-left" fxFlex="60" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center center">
      <img fxFlex="80" fxFlex.xs="80" fxFlex.sm="70" fxFlex.md="65" src="/assets/img/home/nosotros.png">
    </div>
  </div>
</section>