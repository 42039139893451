<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" class="fondo message__content">
        <div fxFlex="70" fxLayout="row wrap" fxLayoutAlign="center center">
            <p class="text" [innerHTML]="title"></p>
        </div>
        <div class="mb-02" fxFlex="100" fxLayoutAlign="center center">
            <p fxFlex="100" class="text2 | color-purpleD4" fxLayoutAlign="center center" [innerHTML]="message"></p>

        </div>
        <div class="mb-02" fxFlex="100" fxLayoutAlign="center center">
            <button mat-dialog-close id="button" type="button" fxFlex="50" fxFlex.xs="70" class="btn-form" fxLayoutAlign="center center">
                Aceptar
            </button>
        </div>
    </div>
</div>