import { Component, OnInit } from '@angular/core';
import { GlobalUrl } from 'src/app/models/url.model';
import * as moment from 'moment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public year: string = '';
  constructor() { }

  ngOnInit(): void {
    this.year = moment().format('YYYY');
  }

  redesOpen(red: any) {
    switch (red) {
      case 'face':
        window.open(GlobalUrl.PaginaFacebook, '_blank');
        break;
      case 'insta':
        window.open(GlobalUrl.PaginaInstagram, '_blank');
        break;
        case 'link':
          window.open(GlobalUrl.PaginaLinkelin, '_blank');
          break;
      // default:
      //   window.open(GlobalUrl.PaginaLinkelin, '_blank');
      //   break;
    }
  }
}
