<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center stretch" fxHide.xs>
  <span fxFlex="100" class="fondo titulo-pre" fxLayoutAlign="center center">{{ 'LandingPolimentes.presence.presencia' | translate }}</span>
  <video fxFlex="100" src="https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/presencia_web.mp4" loop webkit-playsinline="true" muted onloadedmetadata="this.muted = true"
    autoplay oncanplay="this.play()" autobuffer playsinline controlslist="nodownload"
    type="video/mp4"></video>
</div>
<div class="" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center stretch" fxHide.gt-xs>
  <span fxFlex="100" class="fondo titulo-pre" fxLayoutAlign="center center">{{ 'LandingPolimentes.presence.presencia' | translate }}</span>
  <video fxFlex="100" src="https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/presencia_movil.mp4" loop webkit-playsinline="true" muted onloadedmetadata="this.muted = true"
    autoplay oncanplay="this.play()" autobuffer playsinline controlslist="nodownload"
    type="video/mp4"></video>
</div>
