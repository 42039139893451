import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';
import { ServiceService } from './services/service.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'LandingPolimentes';

  constructor(
    private services: ServiceService,
    private translate: TranslateService
  ) {
    localStorage.setItem('idioma', 'es');
    this.translate.setDefaultLang(localStorage.getItem('idioma'));
    this.translate.use(localStorage.getItem('idioma'));
  }

  ngOnInit(): void {
    AOS.init();
    //this.detectarInfo();
    // console.log('v.1.0.0')// 18/05/22
    console.log('v.1.0.28')// 
  }

  detectarInfo() {
    try {
      this.services.datos().subscribe((res: any) => {
        console.log(res.languages);
        localStorage.setItem('idioma', res.languages.split('-')[0]);
        this.translate.setDefaultLang(localStorage.getItem('idioma'));
        this.translate.use(localStorage.getItem('idioma'));
      });
    } catch (error) {
      localStorage.setItem('idioma', 'es');
      this.translate.setDefaultLang(localStorage.getItem('idioma'));
      this.translate.use(localStorage.getItem('idioma'));
    }
  }
}
