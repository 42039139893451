import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import * as AOS from 'aos';
@Component({
  selector: 'app-do-we-do-it-m',
  templateUrl: './do-we-do-it-m.component.html',
  styleUrls: ['./do-we-do-it-m.component.css']
})
export class DoWeDoItMComponent implements OnInit {
  public carousel = [
    {
      title: 'LandingPolimentes.do-we-do-it.escuchamos',
      image: '/assets/img/home/icono1.png',
      text: 'LandingPolimentes.do-we-do-it.escuchamos-desc',
      url: ''
    },
    {
      title: 'LandingPolimentes.do-we-do-it.desarrollo',
      image: '/assets/img/home/icono2.png',
      text: 'LandingPolimentes.do-we-do-it.desarrollo-desc',
      url: ''
    },
    {
      title: 'LandingPolimentes.do-we-do-it.pruebas',
      image: '/assets/img/home/icono3.png',
      text: 'LandingPolimentes.do-we-do-it.pruebas-desc',
      url: ''
    },
    {
      title: 'LandingPolimentes.do-we-do-it.entrega',
      image: '/assets/img/home/icono4.png',
      text: 'LandingPolimentes.do-we-do-it.entrega-desc',
      url: ''
    },
    {
      title: 'LandingPolimentes.do-we-do-it.seguimiento',
      image: '/assets/img/home/icono5.png',
      text: 'LandingPolimentes.do-we-do-it.seguimiento-desc',
      url: ''
    },
  ];
  public carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    speed: 250,
    point: { visible: true },
    touch: true,
    loop: true,
    load: 2,
    interval: {
      timing: 10000,
      initialDelay: 10000,
    },
    animation: 'lazy',
  };
  constructor() { }

  ngOnInit() {
    AOS.init({
      duration: 2000
    });
  }

}
