import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
@Component({
  selector: 'app-we-are',
  templateUrl: './we-are.component.html',
  styleUrls: ['./we-are.component.css']
})
export class WeAreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    AOS.init({
      duration: 2000
    });
  }

  conoce(){

  }
}
