<section id="form" [id]="'form'" fxFlex="100">
  <div class="fondo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <span class="titulo-form" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
      <span fxFlex="100">{{ 'LandingPolimentes.form.Proyecto' | translate }} </span>
    </span>
    <form data-aos="fade-down" autocomplete="off" [formGroup]="form" (ngSubmit)="submit()" #fo="ngForm" fxFlex="60" fxFlex.xs="95"
      fxFlex.sm="80" fxFlex.md="60" fxFlex.lg="70" fxFlex.xl="60" class="form" fxLayout="row wrap"
      fxLayoutAlign="start center">
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
        <span fxFlex="100" class="titulo2-form">{{ 'LandingPolimentes.form.Datos_Generales' | translate }}</span>
      </div>
      <div id="formulario" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>{{'LandingPolimentes.form.Nombre' | translate }}</mat-label>
          <input type="text" matInput required formControlName="name">
          <mat-error *ngIf="validar ">{{'LandingPolimentes.form.Este_campo_es_requerido' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>{{ 'LandingPolimentes.form.Correo' | translate }}</mat-label>
          <input type="email" matInput required formControlName="email">
          <mat-error *ngIf="validar ">{{'LandingPolimentes.form.Este_campo_es_requerido' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="45" appearance="outline">
          <mat-label>{{ 'LandingPolimentes.form.Telefono' | translate }}</mat-label>
          <input type="tel" matInput required formControlName="phone"  [textMask]="{mask: mask}">
          <mat-error *ngIf="validar ">{{'LandingPolimentes.form.Este_campo_es_requerido' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="45" appearance="outline">
          <mat-label>{{ 'LandingPolimentes.form.Empresa' | translate }}</mat-label>
          <input type="text" matInput required formControlName="company">
          <mat-error *ngIf="validar ">{{'LandingPolimentes.form.Este_campo_es_requerido' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="45" appearance="outline">
          <mat-label>País</mat-label>
          <input type="text" matInput required formControlName="pais">
          <mat-error *ngIf="validar ">{{'LandingPolimentes.form.Este_campo_es_requerido' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="45" appearance="outline">
          <mat-label>{{ 'LandingPolimentes.form.Ciudad' | translate }}</mat-label>
          <input type="text" matInput required formControlName="city">
          <mat-error *ngIf="validar ">{{'LandingPolimentes.form.Este_campo_es_requerido' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center">
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
          <p fxFlex="100" class="titulo3-form">{{ 'LandingPolimentes.form.Podemos' | translate }}</p>
        </div>
        <div id="radio" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center">
          <mat-radio-group fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center"
            formControlName="option">
            <mat-radio-button value="1" fxFlex="49" fxFlex.xs="100">{{ 'LandingPolimentes.form.Desarrollo_web' |
              translate }}</mat-radio-button>
            <mat-radio-button value="4" fxFlex="49" fxFlex.xs="100">{{ 'LandingPolimentes.form.Pasarela_de_pago' |
              translate }}</mat-radio-button>

            <mat-radio-button value="2" fxFlex="49" fxFlex.xs="100">{{ 'LandingPolimentes.form.Sitio_informativo' |
              translate }}</mat-radio-button>
            <mat-radio-button value="5" fxFlex="49" fxFlex.xs="100">{{ 'LandingPolimentes.form.Aplicaciones' | translate
              }}</mat-radio-button>

            <mat-radio-button value="3" fxFlex="49" fxFlex.xs="100">{{ 'LandingPolimentes.form.E-commerce' | translate
              }}</mat-radio-button>
            <mat-radio-button value="6" fxFlex="49" fxFlex.xs="100">{{ 'LandingPolimentes.form.Software_Service' |
              translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div id="formulario" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
          <p fxFlex="100" class="titulo3-form">{{ 'LandingPolimentes.form.Describe_proyecto' | translate }}</p>
        </div>
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>{{ 'LandingPolimentes.form.Mensaje' | translate }}</mat-label>
          <textarea matInput rows="5" #message formControlName="message" maxlength="251"></textarea>
          <mat-hint class="color__white" align="end">{{message.value?.length || 0}}/250</mat-hint>
          <mat-error *ngIf="validar">{{'LandingPolimentes.form.Este_campo_es_requerido' | translate }}</mat-error>
          <mat-error *ngIf="form.get('message').hasError('maxLength')">
            Campo solo puede contener 250 caracteres
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <button class="btn-form">{{ 'LandingPolimentes.form.Enviar' | translate }}</button>
      </div>
    </form>
  </div>
</section>
<app-loading *ngIf="showLoad"></app-loading>
