<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center stretch">
<div class="fondo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">

  <div fxFlex="80" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div class="titulo-ac" fxFlex="100" fxFle0x.xs="60"  fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
      <span>{{ 'LandingPolimentes.alliances-certifications.alianza' | translate }}</span>
    </div>
    <div data-aos="fade-down" class="img-ac" fxFlex="20" fxFlex.xs="24"  fxFlex.sm="28" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
      <img fxFlex="100" src="/assets/img/home/pci.png">
    </div>
    <div data-aos="fade-down" class="img-ac" fxFlex="4.5" fxFlex.xs="6" fxFlex.sm="12" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
    </div>
    <div data-aos="fade-down" class="img-ac" fxFlex="19" fxFlex.xs="24" fxFlex.sm="28" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
      <img fxFlex="60" src="/assets/img/home/zendesk.png">
    </div>
    <div data-aos="fade-down" class="img-ac" fxFlex="4.5" fxFlex.xs="6" fxFlex.sm="12" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
    </div>
    <div data-aos="fade-down" class="img-ac" fxFlex="19" fxFlex.xs="24" fxFlex.sm="28" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
      <img fxFlex="100" src="/assets/img/home/twilio.png">
    </div>
    <div data-aos="fade-down" class="img-ac" fxFlex="9.5" fxFlex.xs="12" fxFlex.sm="14" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
    </div>
    <!-- <div data-aos="fade-down" class="img-ac" fxFlex="19" fxFlex.xs="24" fxFlex.sm="28" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
      <img fxFlex="80" src="/assets/img/home/salesforce.png">
    </div> -->
    <div data-aos="fade-down" class="img-ac" fxFlex="19" fxFlex.xs="24" fxFlex.sm="28" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
      <img fxFlex="60" src="/assets/img/home/aws.png">
    </div>
    <div data-aos="fade-down" class="img-ac" fxFlex="14" fxFlex.xs="24" fxFlex.sm="28" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
      <img fxFlex="100" src="/assets/img/home/google.png">
    </div>
    <div data-aos="fade-down" class="img-ac" fxFlex="17" fxFlex.xs="24" fxFlex.sm="28" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
      <img fxFlex="70" src="/assets/img/home/binance.png">
    </div>
    <div data-aos="fade-down" class="img-ac" fxFlex="17" fxFlex.xs="24" fxFlex.sm="28" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
      <img fxFlex="60" src="/assets/img/home/kraken.png">
    </div>
    <div data-aos="fade-down" class="img-ac" fxFlex="17" fxFlex.xs="24" fxFlex.sm="28" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
      <img fxFlex="60" src="/assets/img/home/mastercard.png">
    </div>
  </div>
</div>
<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.xs>
  <video class="video-ac" fxFlex="100"
  src="https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/academy_web.mp4" loop autoplay autobuffer
  oncanplay="this.play()" webkit-playsinline="true" muted onloadedmetadata="this.muted = true" playsinline controlslist="nodownload"
  type="video/mp4"></video>
</div>

<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.gt-xs>
  <video class="video-ac" fxFlex="100"
  src="https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/academy_movil.mp4" loop autoplay autobuffer
  oncanplay="this.play()" webkit-playsinline="true" muted onloadedmetadata="this.muted = true" playsinline controlslist="nodownload"
  type="video/mp4"></video>
</div>
</div>
