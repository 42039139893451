<section fxFlex="100">
  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="carrousel" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-evenly stretch">
      <ngu-carousel #myCarousel [inputs]="carouselTileConfig" [dataSource]="carousel" fxFlex="100">
        <ngu-tile *nguCarouselDef="let carousel">
          <div fxFlex="100" fxLayout="row wrap" class="banner" fxLayoutAlign="center stretch">
            <video fxFlex="100" fxLayout="row wrap" class="video" loop autoplay oncanplay="this.play()" autobuffer
              webkit-playsinline="true" muted onloadedmetadata="this.muted = true" playsinline controlslist="nodownload"
              [src]="carousel.video" type="video/mp4"></video>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <span fxFlex="100" class="banner-frases" [innerHTML]="carousel.frases | translate"></span>
            </div>
          </div>
        </ngu-tile>
        <ul class="myPoint" NguCarouselPoint>
          <li *ngFor="let i of myCarousel.pointNumbers" [class.active]="i==myCarousel.activePoint"
            (click)="myCarousel.moveTo(i)"></li>
        </ul>
      </ngu-carousel>
      <div class="flechas" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-evenly stretch">
        <img fxFlex="100"  src="/assets/icons/flechas.svg" [routerLink]="['.']" [fragment]="'us'">
      </div>
    </div>
  </div>
</section>
