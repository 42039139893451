import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
@Component({
  selector: 'app-do-we-do-it',
  templateUrl: './do-we-do-it.component.html',
  styleUrls: ['./do-we-do-it.component.css']
})
export class DoWeDoItComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    AOS.init({
      duration: 2000
    });
  }

}
