import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import * as AOS from 'aos';
@Component({
  selector: 'app-banner-m',
  templateUrl: './banner-m.component.html',
  styleUrls: ['./banner-m.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BannerMComponent implements OnInit {
  public carousel = [
    {
      video:
        'https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/principal_movil.mp4',
      frases: '',
    },
    {
      video:
        'https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/carrusel_movil.mp4',
      frases: 'LandingPolimentes.banner-m.hola',
    },
    {
      video:
        'https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/carrusel_movil.mp4',
      frases: 'LandingPolimentes.banner-m.tenemos',
    },
    {
      video:
        'https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/carrusel_movil.mp4',
      frases: 'LandingPolimentes.banner-m.somos',
    },
    {
      video:
        'https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/carrusel_movil.mp4',
      frases: 'LandingPolimentes.banner-m.redefinimos',
    },
  ];
  public carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    speed: 250,
    point: { visible: true },
    touch: true,
    loop: true,
    load: 2,
    interval: {
      timing: 10000,
      initialDelay: 10000,
    },
    animation: 'lazy',
  };
  constructor() {}

  ngOnInit() {
    AOS.init({
      duration: 2000,
    });
  }
}
