import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalUrl } from 'src/app/models/url.model';

@Component({
  selector: 'app-navbar-list',
  templateUrl: './navbar-list.component.html',
  styleUrls: ['./navbar-list.component.css']
})
export class NavbarListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }
  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }
  redesOpen(red: any) {
    switch (red) {
      case 'face':
        window.open(GlobalUrl.PaginaFacebook, '_blank');
        break;
      case 'insta':
        window.open(GlobalUrl.PaginaInstagram, '_blank');
        break;
      case 'link':
        window.open(GlobalUrl.PaginaLinkelin, '_blank');
        break;
      // default:
      //   window.open(GlobalUrl.PaginaLinkelin, '_blank');
      //   break;
    }
  }


}
