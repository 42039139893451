<section id="hacemos-m" [id]="'hacemos-m'" fxFlex="100">
  <div class="fondo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="title-ch" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
      <span>¿Cómo lo hacemos?</span>
    </div>
    <ngu-carousel #myCarousel [inputs]="carouselTileConfig" [dataSource]="carousel" fxFlex="80">
      <ngu-tile *nguCarouselDef="let carousel; let j = index">
        <div fxFlex="100" class="card" fxLayout="row wrap" fxLayoutAlign="center stretch">
          <div class="card-num">
            <img class="img-num" src="/assets/img/home/rombo.png">
            <span class="text-num">{{j+1}}</span>
          </div>
          <div class="card1" fxLayout="row wrap" fxLayoutAlign="center stretch">
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <img fxFlex="50" src="{{carousel.image}}">
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <span fxFlex="100" class="card-title-ch">{{carousel.title | translate}}</span>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <span fxFlex="100" class="card-text-ch">{{carousel.text | translate}}</span>
            </div>
          </div>
        </div>
      </ngu-tile>
      <ul class="myPoint3" NguCarouselPoint>
        <li *ngFor="let i of myCarousel.pointNumbers" [class.active]="i==myCarousel.activePoint"
          (click)="myCarousel.moveTo(i)"></li>
      </ul>
    </ngu-carousel>
  </div>
</section>

