<section id="what-do-we-do" [id]="'what-do-we-do'">
  <div class="fondo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="60" fxFlex.xs="80" fxFlex.md="95" data-aos="fade-up" fxLayout="row wrap" class="nosotros"
      fxLayoutAlign="center center">
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <span class="titulo-h">{{ 'LandingPolimentes.to-do.hacemos' | translate }}</span>
      </div>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <span class="subtitulo-h">{{ 'LandingPolimentes.to-do.sabes' | translate }} </span>
      </div>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <span  class="subtitulo2-h">{{ 'LandingPolimentes.to-do.aplicaciones' | translate }} </span>
      </div>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <span class="text-h" [innerHTML]="'LandingPolimentes.to-do.hacemos-desc' | translate"></span>
      </div>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <span fxFlex="100" class="text-h">Algunos ejemplos de SaaS son:</span>
      </div>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" >
        <img class="img-hacemos" fxFlex="60" fxFlex.xs="100" fxFlex.sm="85"  fxFlex.md="80" fxFlex.lg="65" src="/assets/img/home/hacemos.png" alt="">
      </div>
    </div>
  </div>
</section>
