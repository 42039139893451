<div class="content" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center stretch">
    <p class="title" fxFlex="100" fxLayoutAlign="center center">{{ 'LandingPolimentes.join-up.nosotros' | translate }}</p>
    <div class="section" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="60" fxFlex.xs="80" fxFlex.sm="80" fxFlex.xl="52" fxFlex.md="90" fxLayout="row wrap"
            fxLayoutAlign="space-between stretch" fxLayoutAlign.xs="center center">
            <div fxFlex="55" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start start">
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
                    <span class="section__subtitle" fxFlex="100">
                       {{ 'LandingPolimentes.join-up.nosotros-desc1' | translate }}
                    </span>
                    <p class="section__text mb-20">
                       {{ 'LandingPolimentes.join-up.nosotros-desc2' | translate }}
                    </p>
                    <span class="section__title" fxFlex="100">{{ 'LandingPolimentes.join-up.nosotros-l1' | translate }}</span>
                    <span fxFlex="100" class="list_text">{{ 'LandingPolimentes.join-up.nosotros-l2' | translate }}</span>
                    <span fxFlex="100" class="list_text">{{ 'LandingPolimentes.join-up.nosotros-l3' | translate }}</span>
                    <span fxFlex="100" class="list_text">{{ 'LandingPolimentes.join-up.nosotros-l4' | translate }}</span>
                    <span fxFlex="100" class="list_text">{{ 'LandingPolimentes.join-up.nosotros-l5' | translate }}</span>
                    <span fxFlex="100" class="list_text">{{ 'LandingPolimentes.join-up.nosotros-l6' | translate }}</span>
                    <span fxFlex="100" class="list_text">{{ 'LandingPolimentes.join-up.nosotros-l7' | translate }}</span>
                </div>
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxHide.xs fxHide.sm>
                    <p class="title" fxFlex="100" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">{{ 'LandingPolimentes.join-up.vacantes' | translate }}
                    </p>
                    <div class="vacante" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start stretch"
                        *ngFor="let vacante of vacantes">
                        <div [ngClass]="vacante.id==active ? 'vacante__title--active' : ''" class="vacante__title"
                            fxFlex="100" fxLayoutAlign="space-between center" (click)="changeVac(vacante.id)">
                            <span fxFlex="95" [innerHTML]="vacante.nombre | translate"></span>
                            <img src="/assets/icons/arrow-down.svg" *ngIf="vacante.id!=active">
                            <img src="/assets/icons/arrow-up.svg" *ngIf="vacante.id==active">
                        </div>
                        <div class="vacante__content" fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center"
                            *ngIf="vacante.id==active">
                            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
                                <span class="vacante__subtitle" fxFLex="100">{{ 'LandingPolimentes.join-up.perfil' | translate }}</span>
                                <span>{{vacante.perfil | translate}}</span>
                            </div>
                            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
                                <span class="vacante__subtitle" fxFLex="100">{{ 'LandingPolimentes.join-up.habilidades' | translate }}</span>
                                <span fxFlex="100" *ngFor="let skill of vacante.skills">-{{skill |translate}}</span>
                            </div>
                            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
                                <span class="vacante__subtitle" fxFLex="100">{{ 'LandingPolimentes.join-up.conocimientos' | translate }}</span>
                                <span fxFlex="100"
                                    *ngFor="let conocimiento of vacante.conocimientos">-{{conocimiento}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div #spacer style="height: auto;" fxFlex="40" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                fxLayoutAlign="center start" fxHide.xs fxHide.sm>
                <div class="stick_content" stickyThing [boundary]="spacer" id="join" fxFlex="100" fxLayout="row wrap"
                    fxLayoutAlign="center stretch">
                    <form class="form__content" fxFlex="90" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                        fxLayoutAlign="center start" autocomplete="off" [formGroup]="form" (ngSubmit)="submit()" #fo="ngForm">
                        <span class="section__subtitle" fxFlex="100" fxLayoutAlign="center start">Únete a nuestro
                            equipo</span>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                            <mat-label>Nombre completo</mat-label>
                            <input fxFlex="100" matInput type="text" formControlName="name" required>
                            <mat-error>
                                {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                            <mat-label>Correo</mat-label>
                            <input fxFlex="100" matInput type="text" formControlName="email" required>
                            <mat-error>
                                {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                            <mat-label>Teléfono</mat-label>
                            <input fxFlex="100" matInput type="tel" formControlName="phone" required [textMask]="{mask: mask}">
                            <mat-error>
                                {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                            <mat-label>Vacante</mat-label>
                            <input fxFlex="100" matInput type="text" formControlName="vacant" required>
                            <mat-error>
                                {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                            <mat-label>Mensaje</mat-label>
                            <input fxFlex="100" matInput type="tel" formControlName="message" required>
                            <mat-error>
                                {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                          <mat-label>Añade o arrastra tu CV</mat-label>
                          <mat-icon matPrefix fxLayoutAlign="start center"><img fxFlex="80" src="/assets/icons/file.svg">   </mat-icon>
                          <ngx-mat-file-input formControlName="CV" [accept]="'.pdf'" (change)="fileChange($event)" required></ngx-mat-file-input>
                          <mat-error>
                            {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                        </mat-error>
                        </mat-form-field>
                        <div fxFlex="80" fxFlex.xs="90" fxLayout="row">
                            <button fxFlex="50" fxFlex.xs="70" class="btn-form" fxLayoutAlign="center center">
                                Enviar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- contacto Movil -->
            <div fxFlex="40" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center start"
                fxHide.gt-sm>
                <div fxFlex="100" id="join" fxLayout="row wrap" fxLayoutAlign="center stretch">
                    <form class="form__content" fxFlex="90" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                        fxLayoutAlign="center start" autocomplete="off" [formGroup]="formM" (ngSubmit)="submitMovil()" #fo2="ngForm">
                        <span class="section__subtitle" fxFlex="100" fxLayoutAlign="center start">Únete a nuestro
                            equipo</span>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                            <mat-label>Nombre completo</mat-label>
                            <input fxFlex="100" matInput type="text" formControlName="name" required>
                            <mat-error>
                                {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                            <mat-label>Correo</mat-label>
                            <input fxFlex="100" matInput type="text" formControlName="email" required>
                            <mat-error>
                                {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                            <mat-label>Teléfono</mat-label>
                            <input fxFlex="100" matInput type="tel" formControlName="phone" required [textMask]="{mask: mask}">
                            <mat-error>
                                {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                            <mat-label>Vacante</mat-label>
                            <input fxFlex="100" matInput type="text" formControlName="vacant" required>
                            <mat-error>
                                {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                            <mat-label>Mensaje</mat-label>
                            <input fxFlex="100" matInput type="tel" formControlName="message" required>
                            <mat-error>
                                {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="80" fxFlex.xs="90" appearance="outline">
                            <mat-label>Añade o arrastra tu CV</mat-label>
                            <mat-icon matPrefix fxLayoutAlign="start center"><img fxFlex="80"
                                    src="/assets/icons/file.svg"> </mat-icon>
                            <ngx-mat-file-input formControlName="CV" [accept]="'.pdf'" (change)="fileChange2($event)" required></ngx-mat-file-input>
                            <mat-error>
                                {{ 'LandingPolimentes.join-up.campo-requerido' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <div fxFlex="80" fxFlex.xs="90" fxLayout="row">
                            <button fxFlex="50" fxFlex.xs="70" class="btn-form" fxLayoutAlign="center center">
                                Enviar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Fin contacto Movil -->
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxHide.gt-sm>
                <p class="title" fxFlex="100" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">{{ 'LandingPolimentes.join-up.vacantes' | translate }}</p>
                <div class="vacante" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start stretch"
                    *ngFor="let vacante of vacantes">
                    <div [ngClass]="vacante.id==active ? 'vacante__title--active' : ''" class="vacante__title"
                        fxFlex="100" fxLayoutAlign="space-between center" (click)="changeVac(vacante.id)">
                        <span fxFlex="95" [innerHTML]="vacante.nombre | translate"></span>
                        <img src="/assets/icons/arrow-down.svg" *ngIf="vacante.id!=active">
                        <img src="/assets/icons/arrow-up.svg" *ngIf="vacante.id==active">
                    </div>
                    <div class="vacante__content" fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center"
                        *ngIf="vacante.id==active">
                        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
                            <span class="vacante__subtitle" fxFLex="100">{{ 'LandingPolimentes.join-up.perfil' | translate }}</span>
                            <span>{{vacante.perfil | translate}}</span>
                        </div>
                        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
                            <span class="vacante__subtitle" fxFLex="100">{{ 'LandingPolimentes.join-up.habilidades' | translate }}</span>
                            <span fxFlex="100" *ngFor="let skill of vacante.skills">-{{skill |translate}}</span>
                        </div>
                        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
                            <span class="vacante__subtitle" fxFLex="100">{{ 'LandingPolimentes.join-up.conocimientos' | translate }}</span>
                            <span fxFlex="100"
                                *ngFor="let conocimiento of vacante.conocimientos">-{{conocimiento}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center" fxHide.xs>
        <video fxFlex="100" loop autoplay oncanplay="this.play()" autobuffer
        playsinline controlslist="nodownload" webkit-playsinline="true" muted onloadedmetadata="this.muted = true"
            src="https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/academy_web.mp4" type="video/mp4">
        </video>
    </div>
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center" fxHide.gt-xs >
        <video fxFlex="100" loop autoplay oncanplay="this.play()" autobuffer
        playsinline controlslist="nodownload" webkit-playsinline="true" muted onloadedmetadata="this.muted = true"
            src="https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/academy_movil.mp4" type="video/mp4">
        </video>
    </div>
    <div class="section2" fxFlex="100" fxFlex.xs="80" fxFlex.sm="80" fxFlex.xl="52" fxFlex.md="90" fxLayout="row wrap"
        fxLayoutAlign="center center">
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <span fxFlex="100" class="tools__title">¿Te gustaría ser parte <br> de nuestro equipo?</span>
        </div>
    </div>
</div>
<app-loading *ngIf="showLoad"></app-loading>
