

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

/**/
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/**Translate */
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

/**Mask */
import { TextMaskModule } from 'angular2-text-mask';

/**Flex */
import { FlexLayoutModule } from '@angular/flex-layout';

/**Angular Material */
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { MaterialFileInputModule } from 'ngx-material-file-input';

/**Carrusel */
import { NguCarouselModule } from '@ngu/carousel';

/*Componentes y Views*/
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NavbarListComponent } from './components/navbar-list/navbar-list.component';
import { HomeComponent } from './views/home/home.component';
import { UsComponent } from './views/us/us.component';
import { WhatDoWeDoComponent } from './views/what-do-we-do/what-do-we-do.component';
import { JoinUpComponent } from './views/join-up/join-up.component';
import { BannerComponent } from './components/home/banner/banner.component';
import { BannerMComponent } from './components/home/banner-m/banner-m.component';
import { WeAreComponent } from './components/home/we-are/we-are.component';
import { ToDoComponent } from './components/home/to-do/to-do.component';
import { DigitizeComponent } from './components/home/digitize/digitize.component';
import { DigitizeMComponent } from './components/home/digitize-m/digitize-m.component';
import { DoWeDoItComponent } from './components/home/do-we-do-it/do-we-do-it.component';
import { DoWeDoItMComponent } from './components/home/do-we-do-it-m/do-we-do-it-m.component';
import { PresenceComponent } from './components/home/presence/presence.component';
import { AlliancesCertificationsComponent } from './components/home/alliances-certifications/alliances-certifications.component';
import { FormComponent } from './components/home/form/form.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ErrorComponent } from './components/message/error/error.component';
import { SuccessComponent } from './components/message/success/success.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    LoadingComponent,
    NavbarListComponent,
    HomeComponent,
    UsComponent,
    WhatDoWeDoComponent,
    JoinUpComponent,
    BannerComponent,
    BannerMComponent,
    WeAreComponent,
    ToDoComponent,
    DigitizeComponent,
    DigitizeMComponent,
    DoWeDoItComponent,
    DoWeDoItMComponent,
    PresenceComponent,
    AlliancesCertificationsComponent,
    FormComponent,
    NotfoundComponent,
    ErrorComponent,
    SuccessComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NguCarouselModule,
    AngularStickyThingsModule,
    MaterialFileInputModule,
    TextMaskModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],

})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 60]);
    router.events
      .pipe(filter((e) => e instanceof Scroll))
      .subscribe((e: Scroll) => {
        if (e.anchor) {
          // anchor navigation
          setTimeout(() => {
            viewportScroller.scrollToAnchor(e.anchor);
          });
        } else if (e.position) {
          // backward navigation
          viewportScroller.scrollToPosition(e.position);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
