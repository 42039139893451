import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import * as AOS from 'aos';
@Component({
  selector: 'app-digitize-m',
  templateUrl: './digitize-m.component.html',
  styleUrls: ['./digitize-m.component.css']
})
export class DigitizeMComponent implements OnInit {
  public carousel = [
    {
      title: 'LandingPolimentes.digitize.desarrollo-web',
      image: '/assets/img/home/desarrollo-web.png',
      text: 'LandingPolimentes.digitize.desarrollo-web-desc',
      url: '/what-do-we-do'
    },
    {
      title: 'LandingPolimentes.digitize.sitio-informativo',
      image: '/assets/img/home/sitio-informativo.png',
      text: 'LandingPolimentes.digitize.sitio-informativo-desc',
      url: '/what-do-we-do'
    },
    {
      title: 'LandingPolimentes.digitize.aplicaciones',
      image: '/assets/img/home/aplicaciones.png',
      text: 'LandingPolimentes.digitize.aplicaciones-desc',
      url: '/what-do-we-do'
    },
    {
      title: 'LandingPolimentes.digitize.commerce',
      image: '/assets/img/home/ecommerce.png',
      text: 'LandingPolimentes.digitize.commerce-desc',
      url: '/what-do-we-do'
    },
    {
      title: 'LandingPolimentes.digitize.pasarela',
      image: '/assets/img/home/pasarela-pago.png',
      text: 'LandingPolimentes.digitize.pasarela-desc',
      url: '/what-do-we-do'
    },
  ];
  public carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    speed: 250,
    point: { visible: true },
    touch: true,
    loop: true,
    load: 2,
    interval: {
      timing: 10000,
      initialDelay: 10000,
    },
    animation: 'lazy',
  };

  constructor() { }

  ngOnInit() {
    AOS.init({
      duration: 2000
    });
  }

}
