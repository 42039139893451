import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-us',
  templateUrl: './us.component.html',
  styleUrls: ['./us.component.css'],
})
export class UsComponent implements OnInit {
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang(localStorage.getItem('idioma'));
    this.translate.use(localStorage.getItem('idioma'));
  }

  ngOnInit(): void {}
}
