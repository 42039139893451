
<div class="fondo-m" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
  <div data-aos="fade-down" class="titulo-dt" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <span>Digitalizamos tu negocio</span>
  </div>
  <ngu-carousel #myCarousel [inputs]="carouselTileConfig" [dataSource]="carousel" fxFlex="100" fxFlex.sm="50">
    <ngu-tile *nguCarouselDef="let carousel">
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" class="card">
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
          <span class="titulo-d">{{carousel.title | translate}}</span>
        </div>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <img fxFlex="100" fxFlex.sm="50"  class="img" src="{{carousel.image}}">
        </div>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
          <span class="text-d1">{{carousel.text | translate}}</span>
        </div>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
          <button  [routerLink]="[carousel.url]">{{'LandingPolimentes.digitize.conoce' | translate}}</button>
        </div>
      </div>
    </ngu-tile>
    <ul class="myPoint2" NguCarouselPoint>
      <li *ngFor="let i of myCarousel.pointNumbers" [class.active]="i==myCarousel.activePoint"
        (click)="myCarousel.moveTo(i)"></li>
    </ul>
  </ngu-carousel>
</div>
