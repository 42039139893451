import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';
import { ServiceService } from 'src/app/services/service.service';
import { ErrorComponent } from '../../message/error/error.component';
import { SuccessComponent } from '../../message/success/success.component';
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
})
export class FormComponent implements OnInit {
  @ViewChild('fo') myForm;
  public form: FormGroup;
  public validar: boolean = false;
  public showLoad: boolean = false;
  public longitudTexto: number = 0;
  public mask = [/[1-9]/, /\d/, ' ', /\d/,  /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];

  constructor(private translate: TranslateService,
    public dialog: MatDialog,
    private servicio:ServiceService
    ) {
    translate.use(localStorage.getItem('idioma'));
  }

  ngOnInit() {
    AOS.init({
      duration: 2000,
    });

    this.doForm();
  }

  doForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      pais: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      option: new FormControl('1', [Validators.required]),
      message: new FormControl('', [Validators.required, Validators.maxLength(250)]),
    });
  }

  submit(){
    this.validar = true;
    console.log(this.form);

    if(this.form.invalid){
      this.dialog.open(ErrorComponent, {
        width: '650px',
        data:{
         title: 'Error',
         message: '¡Tus datos no pudieron enviarse correctamente, intente mas tarde!'
        }

      });    } else {
      console.log(this.form.value);
      let data = {
        "datos":{
          "name": this.form.get('name').value,
          "email":this.form.get('email').value,
          "phone_number": (this.form.get('phone').value).split(" ").join(""),
          "message": this.form.get('message').value,
          "language":"es",
          "country":this.form.get('pais').value,
          "company":this.form.get('company').value,
          "city":this.form.get('city').value,
          "projectsId":this.form.get('option').value
      }
      }
      const dataJson = {
        full_name: this.form.value.name,
        email: this.form.value.email,
        phone: data.datos.phone_number,
        company: this.form.value.company,
        country: this.form.value.pais,
        city: this.form.value.city,
        message: this.form.value.message
      }

      this.showLoad = true;
       this.servicio.sendContact(data).subscribe((res)=>{
        this.showLoad = false;
         this.dialog.open(SuccessComponent, {
           width: '650px',
           data:{
            title: '',
            message: '¡Tu mensaje se envió correctamente, gracias por ponerte en contacto con nosotros!'
           }
         });
         console.log(res);
         this.myForm.resetForm();
         this.form.reset();
       },
       (error)=>{
         console.log(error);
        this.showLoad = false;
         this.dialog.open(ErrorComponent, {
           width: '650px',
           data:{
            title: 'Error',
            message: '¡Tus datos no pudieron enviarse correctamente, intente mas tarde!'
           }

         });
         this.myForm.resetForm();
         this.form.reset();
       });
    }
}
validarLongitud() {
  this.longitudTexto = this.form.value.message.length;
}

}
