import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { HomeComponent } from './views/home/home.component';
import { JoinUpComponent } from './views/join-up/join-up.component';
import { UsComponent } from './views/us/us.component';
import { WhatDoWeDoComponent } from './views/what-do-we-do/what-do-we-do.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 0],
  onSameUrlNavigation: 'reload',
  relativeLinkResolution: 'legacy',
  initialNavigation: 'enabled',
  paramsInheritanceStrategy: 'always'
};

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'us', component: UsComponent },
  { path: 'what-do-we-do', component: WhatDoWeDoComponent },
  { path: 'join-up', component: JoinUpComponent },
  { path: '404', component: NotfoundComponent},
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '**', pathMatch: 'full', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
