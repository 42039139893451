<div class="fondo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around stretch">
  <div class="fondo1" fxFlex="25" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="space-around start">
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
      <img fxFlex="70" fxFlex.xl="60" fxFlex.md="70" fxFlex.xs="75" fxFlex.sm="100" [routerLink]="['home']"
        src="/assets/img/logo_footer.png">
    </div>
    <div class="text_footer" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
      <span fxFlex="100">{{ 'LandingPolimentes.footer.conexion' | translate }}</span>
    </div>
    <div fxFlex="100"  fxFlex.xs="70"   fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="space-evenly center">
      <div fxFlex="50" fxFlex.md="58" fxFlex.xs="100" fxFlex.sm="85" fxFlex.xl="43"  fxLayout="row wrap"
        fxLayoutAlign="space-between end" fxLayoutAlign.xs="space-around center">
        <span class="icon iconFac" (click)="redesOpen('face')"></span>
        <span class="icon iconIns" (click)="redesOpen('insta')"></span>
        <span class="icon iconLin" (click)="redesOpen('link')"></span>
      </div>
    </div>
    <div class="div4_footer" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end end" fxHide.xs>
      <span class="text1_footer" fxFlex="100">{{ 'LandingPolimentes.footer.politica' | translate }}</span>
      <span class="text1_footer" fxFlex="100">{{ 'LandingPolimentes.footer.terminos' | translate }}</span>
    </div>
  </div>
  <div class="fondo1 ml-15" fxFlex="25" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="space-around start"
    fxLayoutAlign.xs="center center">
    <div fxFlex="100" class="title1_footer">{{ 'LandingPolimentes.footer.enlaces' | translate }}</div>
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
      <span [routerLink]="['home']" [fragment]="'us'" class="text2_footer" fxFlex="100">{{ 'LandingPolimentes.footer.nosotros' | translate }}</span>
      <span [routerLink]="['home']" [fragment]="'what-do-we-do'" class="text2_footer" fxFlex="100">{{ 'LandingPolimentes.footer.hacer' | translate }}</span>
      <span [routerLink]="['home']" [fragment]="'hacemos'" class="text2_footer" fxFlex="100">{{ 'LandingPolimentes.footer.hacemos' | translate }}</span>
      <!-- <span [routerLink]="['home']" [fragment]="'form'" class="text2_footer" fxFlex="100">{{ 'LandingPolimentes.footer.contacto' | translate }}</span> -->
      <!-- <span [routerLink]="['join-up']" class="text2_footer" fxFlex="100">{{ 'LandingPolimentes.footer.trabajo' | translate }}</span> -->
    </div>
  </div>
  <div class="fondo1" fxFlex="30" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="space-around start">
    <div fxFlex="100" class="title1_footer">{{ 'LandingPolimentes.footer.contacto' | translate }}</div>
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
      <div class="div3_footer" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
        <img fxFlex="10" class="icon_footer-mail" src="/assets/icons/email.svg"><a href="mailto:'contacto@polimentes.com'"  class="text3_footer"
          fxFlex="90">contacto@polimentes.com</a>
      </div>
      <div class="div3_footer" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
        <img fxFlex="10" class="icon_footer-places" src="/assets/icons/places.svg"><span class="text3_footer"
          fxFlex="90">Ciudad de México, México.</span>
        <img fxFlex="10" class="icon_footer-mobil" src="/assets/icons/mobil.svg"><a href="tel:+525590460283"  class="text3_footer"
          fxFlex="90">+52
          (55) 90460283 </a>
        <img fxFlex="10" class="icon_footer-mobil" src="/assets/icons/mobil.svg"><a href="tel:+525590460284" class="text3_footer"
          fxFlex="90">+52
          (55) 90460284</a>
      </div>
      <div class="div3_footer" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
        <img fxFlex="10" class="icon_footer-places" src="/assets/icons/places.svg"><span class="text3_footer"
          fxFlex="90">Guadalajara, Jalisco.</span>
        <img fxFlex="10" class="icon_footer-mobil" src="/assets/icons/mobil.svg"><a href="tel:+523316534247" class="text3_footer"
          fxFlex="90">+52
          (33) 16534247 </a>
      </div>
      <div class="div3_footer" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
        <img fxFlex="10" class="icon_footer-places" src="/assets/icons/places.svg"><span class="text3_footer"
          fxFlex="90">Medellín, Colombia.</span>
      </div>
    </div>
    <div class="div4_footer" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end end" fxHide.gt-xs>
      <span class="text1_footer" fxFlex="100">{{ 'LandingPolimentes.footer.politica' | translate }}</span>
      <span class="text1_footer" fxFlex="100">{{ 'LandingPolimentes.footer.terminos' | translate }}</span>
    </div>
  </div>

  <div class="fondo2" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center stretch">
    <span class="copy" fxFlex="100" fxLayoutAlign="center center" fxHide.gt-xs> Copyright © {{year}}</span>
    <span class="copy" fxFlex="100" fxLayoutAlign="center center" fxHide.gt-xs> {{ 'LandingPolimentes.footer.desarrollo' | translate }} <img class="img-icon"
        src="/assets/img/corazon.svg"> {{ 'LandingPolimentes.footer.por' | translate }} <img class="img-icon" [routerLink]="'/'"
        src="/assets/img/icon_poli2.png"></span>
    <span class="copy" fxFlex="100" fxLayoutAlign="center center" fxHide.xs>Copyright © {{year}} | {{ 'LandingPolimentes.footer.desarrollo' | translate }} <img
        class="img-icon" src="/assets/img/corazon.svg"> {{ 'LandingPolimentes.footer.por' | translate }} <img class="img-icon" [routerLink]="'/'"
        src="/assets/img/icon_poli2.png"></span>
  </div>

</div>
