import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  public message: any = '';
  public title: any = '';
  public contador={
    segundos:5,
    intervalo:null,
  };

  constructor(public dialogRef: MatDialogRef<SuccessComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.title = data.title;
    this.message = data.message;
   }

  ngOnInit() {
    this.conteo();
  }


  aceptar(){this.dialogRef.close();}

  conteo() {
    this.contador.intervalo = setInterval(() => {
      this.contador.segundos--;
      if (this.contador.segundos === 0) {
        clearInterval(this.contador.intervalo);
        this.aceptar();
      }
    }, 5000);
  }
}
