<div class="landing-nav" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-toolbar>
        <nav class="nav-pdn" fxFlex="100">
            <ul fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center">
                <div class="iconLogo" fxFlex="20" fxLayout="row wrap" fxLayoutAlign="center center">
                    <img class="logo" [routerLink]="['home']" src="/assets/img/logo_nav.png" alt="">
                </div>
                <div class="vinculo" fxFlex="60" fxLayout="row wrap" fxLayoutAlign="end center" fxHide.xs fxHide.sm>
                    <a [routerLink]="['home']" [class.active]="fragment == 'home'">{{ 'LandingPolimentes.navbar.inicio' | translate }}</a>
                    <a [routerLink]="['home']" [fragment]="'us'" [class.active]="fragment == 'us'">{{ 'LandingPolimentes.navbar.nosotros' | translate }}</a>
                    <a [routerLink]="['home']" [fragment]="'what-do-we-do'" [class.active]="fragment == 'what-do-we-do'">{{ 'LandingPolimentes.navbar.hacemos' | translate }}</a>
                    <a [routerLink]="['home']" [fragment]="'hacemos'" [class.active]="fragment == 'hacemos'">{{ 'LandingPolimentes.navbar.como' | translate }}</a>
                    <!-- <a [routerLink]="['home']" [fragment]="'form'" [class.active]="fragment == 'form'">{{ 'LandingPolimentes.navbar.contacto' | translate }}</a> -->
                    <!-- <a [routerLink]="['join-up']" [class.active]="fragment == 'join-up'">{{ 'LandingPolimentes.navbar.unete' | translate }}</a> -->
                </div>
                <div class="vinculo" fxFlex="20" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.xs fxHide.sm>
                  <span class="icon iconFac" (click)="redesOpen('face')"></span>
                  <span class="icon iconIns" (click)="redesOpen('insta')"></span>
                  <span class="icon iconLin" (click)="redesOpen('link')"></span>
                </div>
                <div fxFlex="50" fxLayoutAlign="end center" fxHide.gt-sm>
                    <img class="icon" src="/assets/icons/menu.svg" alt="" (click)="onToggleSidenav()">
                </div>
            </ul>
        </nav>

    </mat-toolbar>
</div>
