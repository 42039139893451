
  <div class="fondo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center stretch">
    <div data-aos="fade-down" class="titulo-d" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
      <span>{{ 'LandingPolimentes.digitize.digitalizamos' | translate }}</span>
    </div>
    <div class="fondo-dn" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center">
      <div fxFlex="30" data-aos="fade-right" fxLayout="row wrap" fxLayoutAlign="space-around center">
        <div fxFlex="100" [ngClass]="negocio == 'desarrollo' ? 'text-d1-active':'text-d1'" fxLayout="row wrap"
          fxLayoutAlign="end center">
          <span (click)="mostrar('desarrollo')" fxLayoutAlign="center center">{{ 'LandingPolimentes.digitize.desarrollo-web' | translate }} <span class="icon"
              [ngClass]="negocio == 'desarrollo' ? 'fizquierda-active':'fizquierda'"></span></span>
        </div>
        <div fxFlex="100" [ngClass]="negocio == 'sitio' ? 'text-d1-active':'text-d1'" fxLayout="row wrap"
          fxLayoutAlign="end center">
          <span (click)="mostrar('sitio')" fxLayoutAlign="center center">{{ 'LandingPolimentes.digitize.sitio-informativo' | translate }} <span class="icon"
              [ngClass]="negocio == 'sitio' ? 'fizquierda-active':'fizquierda'"></span></span>
        </div>
        <div fxFlex="100" [ngClass]="negocio == 'aplicaciones' ? 'text-d1-active':'text-d1'" fxLayout="row wrap"
          fxLayoutAlign="end center">
          <span (click)="mostrar('aplicaciones')" fxLayoutAlign="center center">{{ 'LandingPolimentes.digitize.aplicaciones' | translate }} <span class="icon"
              [ngClass]="negocio == 'aplicaciones' ? 'fizquierda-active':'fizquierda'"></span></span>
        </div>
      </div>
      <div data-aos="fade-up" class="fondo-d" fxFlex="40" fxLayout="row wrap" fxLayoutAlign="space-around center"
        [ngSwitch]="negocio">
        <div class="fondo-d-color" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center"></div>
        <div class="fondo-texto-d" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center"
          *ngSwitchCase="'desarrollo'">
          <div fxFlex="40" fxLayout="row wrap" fxLayoutAlign="center center">
            <img class="image-d" fxFlex="100" src="/assets/img/home/desarrollo-web.png">
          </div>
          <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <span class="text-d">{{ 'LandingPolimentes.digitize.desarrollo-web-desc' | translate }}</span>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <button [routerLink]="['/what-do-we-do']">{{ 'LandingPolimentes.digitize.conoce' | translate }}</button>
            </div>
          </div>
        </div>
        <div class="fondo-texto-d" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center"
          *ngSwitchCase="'sitio'">
          <div fxFlex="40" fxLayout="row wrap" fxLayoutAlign="center center">
            <img class="image-d" fxFlex="100" src="/assets/img/home/sitio-informativo.png">
          </div>
          <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <span class="text-d">{{ 'LandingPolimentes.digitize.sitio-informativo-desc' | translate }}</span>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <button [routerLink]="['/what-do-we-do']">{{ 'LandingPolimentes.digitize.conoce' | translate }}</button>
            </div>
          </div>
        </div>
        <div class="fondo-texto-d" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center"
          *ngSwitchCase="'aplicaciones'">
          <div fxFlex="40" fxLayout="row wrap" fxLayoutAlign="center center">
            <img class="image-d" fxFlex="100" src="/assets/img/home/aplicaciones.png">
          </div>
          <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <span class="text-d">{{ 'LandingPolimentes.digitize.aplicaciones-desc' | translate }}</span>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <button [routerLink]="['/what-do-we-do']">{{ 'LandingPolimentes.digitize.conoce' | translate }}</button>
            </div>
          </div>
        </div>
        <div class="fondo-texto-d" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center"
          *ngSwitchCase="'commerce'">
          <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <span class="text-d">{{ 'LandingPolimentes.digitize.commerce-desc' | translate }}</span>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <button [routerLink]="['/what-do-we-do']">{{ 'LandingPolimentes.digitize.conoce' | translate }}</button>
            </div>
          </div>
          <div fxFlex="40" fxLayout="row wrap" fxLayoutAlign="center center">
            <img class="image-d" fxFlex="100" src="/assets/img/home/ecommerce.png">
          </div>
        </div>
        <div class="fondo-texto-d" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center"
          *ngSwitchCase="'pago'">
          <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <span class="text-d">{{ 'LandingPolimentes.digitize.pasarela-desc' | translate }}</span>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <button [routerLink]="['/what-do-we-do']">{{ 'LandingPolimentes.digitize.conoce' | translate }}</button>
            </div>
          </div>
          <div fxFlex="40" fxLayout="row wrap" fxLayoutAlign="center center">
            <img class="image-d" fxFlex="100" src="/assets/img/home/pasarela-pago.png">
          </div>
        </div>
      </div>
      <div fxFlex="30" data-aos="fade-left" fxLayout="row wrap" fxLayoutAlign="space-around center">
        <div fxFlex="100" [ngClass]="negocio == 'commerce' ? 'text-d2-active':'text-d2'" fxLayout="row wrap"
          fxLayoutAlign="start center">
          <span (click)="mostrar('commerce')" fxLayoutAlign="center center">
            <span class="icon" [ngClass]="negocio == 'commerce' ? 'fderecha-active':'fderecha'"></span>
            {{ 'LandingPolimentes.digitize.commerce' | translate }}</span>
        </div>
        <div fxFlex="100" [ngClass]="negocio == 'pago' ? 'text-d2-active':'text-d2'" fxLayout="row wrap"
          fxLayoutAlign="start center">
          <span (click)="mostrar('pago')" fxLayoutAlign="center center">
            <span class="icon" [ngClass]="negocio == 'pago' ? 'fderecha-active':'fderecha'"></span> {{ 'LandingPolimentes.digitize.pasarela' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
