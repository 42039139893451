import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as AOS from 'aos';
@Component({
  selector: 'app-to-do',
  templateUrl: './to-do.component.html',
  styleUrls: ['./to-do.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ToDoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    AOS.init({
      duration: 2000
    });
  }

}
