import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
@Component({
  selector: 'app-digitize',
  templateUrl: './digitize.component.html',
  styleUrls: ['./digitize.component.css']
})
export class DigitizeComponent implements OnInit {
  public negocio: any = 'desarrollo';


  constructor() { }

  ngOnInit() {
    AOS.init({
      duration: 2000
    });
  }

  mostrar(opcion: any) {
    this.negocio = opcion;
  }
}
