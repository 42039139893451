import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalUrl } from 'src/app/models/url.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  public fragment: any = 'home';
  public fragment2: any = 'home';

  constructor(private route: ActivatedRoute, private router: Router) {
    try {
      this.route.fragment.subscribe((fragment) => {
        this.fragment = fragment;//fragment

        /**Detecta el url componente */
        this.router.events.pipe(
          filter((e: any): e is RouterEvent => e instanceof RouterEvent)
        ).subscribe((evt: RouterEvent) => {
          if (evt instanceof NavigationEnd) {
            switch (evt.url) {
              case '/us':
                this.fragment = 'us';
                break;
              case '/what-do-we-do':
                this.fragment = 'what-do-we-do';
                break;
              case '/join-up':
                this.fragment = 'join-up';
                break;
              case '/home':
                this.fragment = 'home';
                break;
            }
          }
        });
      });

    } catch (error) {

    }

  }

  ngOnInit(): void { }
  onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };
  redesOpen(red: any) {
    switch (red) {
      case 'face':
        window.open(GlobalUrl.PaginaFacebook, '_blank');
        break;
      case 'insta':
        window.open(GlobalUrl.PaginaInstagram, '_blank');
        break;
      case 'link':
        window.open(GlobalUrl.PaginaLinkelin, '_blank');
        break;
      // default:
      //   window.open(GlobalUrl.PaginaLinkelin, '_blank');
      //   break;
    }
  }
}
