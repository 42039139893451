<section id="hacemos" [id]="'hacemos'" fxFlex="100" >
  <div class="fondo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="title-ch" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end center">
      <span>{{ 'LandingPolimentes.do-we-do-it.hacemos' | translate }}</span>
    </div>
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around stretch">
      <div fxFlex="17" data-aos="flip-up" fxFlex.sm="30" fxFlex.md="30" class="card" fxLayout="row wrap"
        fxLayoutAlign="center stretch">
        <div fxFlex="100" class="card1" fxLayout="row wrap" fxLayoutAlign="center center">
          <img fxFlex="100" src="/assets/img/home/icono1.png">
        </div>
        <div fxFlex="100" class="card2" fxLayout="row wrap" fxLayoutAlign="space-around stretch">
          <div class="card-num">
            <img class="img-num" src="/assets/img/home/rombo.png">
            <span class="text-num">1</span>
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center end">
            <img fxFlex="40" src="/assets/img/home/icono1.png">
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <span fxFlex="100" class="card-title-ch">{{ 'LandingPolimentes.do-we-do-it.escuchamos' | translate }}</span>
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
            <span fxFlex="100" class="card-text-ch">{{ 'LandingPolimentes.do-we-do-it.escuchamos-desc' | translate }}</span>
          </div>
        </div>
      </div>
      <div fxFlex="17" data-aos="flip-up" fxFlex.sm="30" fxFlex.md="30" class="card" fxLayout="row wrap"
        fxLayoutAlign="center stretch">
        <div fxFlex="100" class="card1" fxLayout="row wrap" fxLayoutAlign="center center">
          <img fxFlex="100" src="/assets/img/home/icono2.png">
        </div>
        <div fxFlex="100" class="card2" fxLayout="row wrap" fxLayoutAlign="space-around stretch">
          <div class="card-num">
            <img class="img-num" src="/assets/img/home/rombo.png">
            <span class="text-num">2</span>
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center end">
            <img fxFlex="40" src="/assets/img/home/icono2.png">
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <span fxFlex="100" class="card-title-ch">{{ 'LandingPolimentes.do-we-do-it.desarrollo' | translate }}</span>
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
            <span fxFlex="100" class="card-text-ch">{{ 'LandingPolimentes.do-we-do-it.desarrollo-desc' | translate }}</span>
          </div>
        </div>
      </div>
      <div fxFlex="17" data-aos="flip-up" fxFlex.sm="30" fxFlex.md="30" class="card" fxLayout="row wrap"
        fxLayoutAlign="center stretch">
        <div fxFlex="100" class="card1" fxLayout="row wrap" fxLayoutAlign="center center">
          <img fxFlex="100" src="/assets/img/home/icono3.png">
        </div>
        <div fxFlex="100" class="card2" fxLayout="row wrap" fxLayoutAlign="space-around stretch">
          <div class="card-num">
            <img class="img-num" src="/assets/img/home/rombo.png">
            <span class="text-num">3</span>
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center end">
            <img fxFlex="40" src="/assets/img/home/icono3.png">
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <span fxFlex="100" class="card-title-ch">{{ 'LandingPolimentes.do-we-do-it.pruebas' | translate }}</span>
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
            <span fxFlex="100" class="card-text-ch">{{ 'LandingPolimentes.do-we-do-it.pruebas-desc' | translate }}</span>
          </div>
        </div>
      </div>
      <div fxFlex="17" data-aos="flip-up" fxFlex.sm="30" fxFlex.md="30" class="card" fxLayout="row wrap"
        fxLayoutAlign="center stretch">
        <div fxFlex="100" class="card1" fxLayout="row wrap" fxLayoutAlign="center center">
          <img fxFlex="100" src="/assets/img/home/icono4.png">
        </div>
        <div fxFlex="100" class="card2" fxLayout="row wrap" fxLayoutAlign="space-around stretch">
          <div class="card-num">
            <img class="img-num" src="/assets/img/home/rombo.png">
            <span class="text-num">4</span>
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center end">
            <img fxFlex="40" src="/assets/img/home/icono4.png">
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <span fxFlex="100" class="card-title-ch">{{ 'LandingPolimentes.do-we-do-it.entrega' | translate }}</span>
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
            <span fxFlex="100" class="card-text-ch">{{ 'LandingPolimentes.do-we-do-it.entrega-desc' | translate }}</span>
          </div>
        </div>
      </div>
      <div fxFlex="17" data-aos="flip-up" fxFlex.sm="30" fxFlex.md="30" class="card" fxLayout="row wrap"
        fxLayoutAlign="center stretch">
        <div fxFlex="100" class="card1" fxLayout="row wrap" fxLayoutAlign="center center">
          <img fxFlex="100" src="/assets/img/home/icono5.png">
        </div>
        <div fxFlex="100" class="card2" fxLayout="row wrap" fxLayoutAlign="space-around stretch">
          <div class="card-num">
            <img class="img-num" src="/assets/img/home/rombo.png">
            <span class="text-num">5</span>
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center end">
            <img fxFlex="40" src="/assets/img/home/icono5.png">
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <span fxFlex="100" class="card-title-ch">{{ 'LandingPolimentes.do-we-do-it.seguimiento' | translate }}</span>
          </div>
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
            <span fxFlex="100" class="card-text-ch">{{ 'LandingPolimentes.do-we-do-it.seguimiento-desc' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

