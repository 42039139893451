<div class="content" fxFlex="100" fxLayout="row wrap" >
    <p class="title" fxFlex="100" fxLayoutAlign="center center">{{ 'LandingPolimentes.what-do-we-do.hacemos' | translate }}</p>
    <div class="section1" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="60" fxFlex.xs="80" fxFlex.sm="80" fxFlex.xl="52" fxFlex.md="90"fxLayout="row wrap" fxLayoutAlign="space-evenly center" fxLayoutAlign.xs="center center">
            <span class="section__title" data-aos="fade-right" data-aos-duration="3000" fxFlex="100">{{ 'LandingPolimentes.what-do-we-do.desarrollo-web' | translate }}</span>
            <div class="content_text" data-aos="fade-right" data-aos-duration="3000" fxFlex="58" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center">
                <span class="section__subtitle" fxFlex="100">
                  {{ 'LandingPolimentes.what-do-we-do.desarrollo-web-desc1' | translate }}
                </span>
                <p class="mb-20">
                    {{ 'LandingPolimentes.what-do-we-do.desarrollo-web-desc2' | translate }}
                </p>
                <p class="mb-20">
                    {{ 'LandingPolimentes.what-do-we-do.desarrollo-web-desc3' | translate }}
                </p>
                <p class="mb-20">
                   {{ 'LandingPolimentes.what-do-we-do.desarrollo-web-desc4' | translate }}
                </p>
            </div>
            <div data-aos="fade-left" data-aos-duration="3000" fxFlex="38" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center">
                <img fxFlex="100" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="95" src="/assets/img/Hacemos/Desarrollo.png">
            </div>
        </div>
    </div>
    <div class="section2" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="60" fxFlex.xs="80" fxFlex.sm="80" fxFlex.xl="52" fxFlex.md="90"fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
            <div data-aos="fade-up" data-aos-duration="3000" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.xs fxHide.sm>
                <img fxFlex="65" fxFlex.sm="30" fxFlex.md="95" src="/assets/img/Hacemos/Sitio.png">
            </div>
            <div data-aos="fade-down" data-aos-duration="3000" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start start">
                <span class="section__title" fxFlex="100">{{ 'LandingPolimentes.what-do-we-do.sitio-info' | translate }}</span>
                <span class="section__subtitle" fxFlex="100">
                    {{ 'LandingPolimentes.what-do-we-do.sitio-info-desc1' | translate }}
                </span>
                <p class="mb-20">
                   {{ 'LandingPolimentes.what-do-we-do.sitio-info-desc2' | translate }}
                </p>
                <p class="mb-20">
                   {{ 'LandingPolimentes.what-do-we-do.sitio-info-desc3' | translate }}
                </p>
                <p class="mb-20">
                   {{ 'LandingPolimentes.what-do-we-do.sitio-info-desc4' | translate }}
                </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.sm="center center" fxHide.gt-sm>
                <img fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="95" src="/assets/img/Hacemos/Sitio.png">
            </div>
        </div>
    </div>
    <div class="section3" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="60" fxFlex.xs="80" fxFlex.sm="80" fxFlex.xl="52" fxFlex.md="90"fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
            <div data-aos="fade-right" data-aos-duration="3000" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start start">
                <span class="section__title" fxFlex="100">{{ 'LandingPolimentes.what-do-we-do.aplicacion' | translate }}</span>
                <span class="section__subtitle" fxFlex="100">
                   {{ 'LandingPolimentes.what-do-we-do.aplicacion-desc1' | translate }}
                </span>
                <p class="mb-20">
                    {{ 'LandingPolimentes.what-do-we-do.aplicacion-desc2' | translate }}
                </p>
                <p class="mb-20">
                    {{ 'LandingPolimentes.what-do-we-do.aplicacion-desc3' | translate }}
                </p>
                <p class="mb-20">
                   {{ 'LandingPolimentes.what-do-we-do.aplicacion-desc4' | translate }}
                </p>
                <p class="mb-20">
                    {{ 'LandingPolimentes.what-do-we-do.aplicacion-desc5' | translate }}
                </p>
            </div>
            <div data-aos="fade-left" data-aos-duration="3000" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center">
                <img fxFlex="70" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="95" src="/assets/img/Hacemos/App.png">
            </div>
        </div>
    </div>
    <div class="section" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="60" fxFlex.xs="80" fxFlex.sm="80" fxFlex.xl="52" fxFlex.md="90"fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
            <div data-aos="fade-up" data-aos-duration="3000" fxFlex="32" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.xs fxHide.sm>
                <img fxFlex="95" fxFlex.sm="30" fxFlex.md="95" src="/assets/img/Hacemos/E-Commerce.png">
            </div>
            <div data-aos="fade-down" data-aos-duration="3000" fxFlex="63" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start start">
                <span class="section__title" fxFlex="100">{{ 'LandingPolimentes.what-do-we-do.commerce' | translate }}</span>
                <span class="section__subtitle" fxFlex="100">
                    {{ 'LandingPolimentes.what-do-we-do.commerce-desc1' | translate }}
                </span>
                <p class="mb-20">
                    {{ 'LandingPolimentes.what-do-we-do.commerce-desc2' | translate }}
                </p>
                <p class="mb-20">
                   {{ 'LandingPolimentes.what-do-we-do.commerce-desc3' | translate }}
                </p>
                <p class="mb-20">
                    {{ 'LandingPolimentes.what-do-we-do.commerce-desc4' | translate }}
                </p>
                <p class="mb-20">
                    {{ 'LandingPolimentes.what-do-we-do.commerce-desc5' | translate }}
                </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.sm="center center" fxHide.gt-sm>
                <img fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="95" src="/assets/img/Hacemos/E-Commerce.png">
            </div>
        </div>
    </div>
    <div class="section4" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="60" fxFlex.xs="80" fxFlex.sm="80" fxFlex.xl="52" fxFlex.md="90"fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
            <div data-aos="fade-right" data-aos-duration="3000" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start start">
                <span class="section__title" fxFlex="100">{{ 'LandingPolimentes.what-do-we-do.pago' | translate }}</span>
                <span class="section__subtitle" fxFlex="100">
                    {{ 'LandingPolimentes.what-do-we-do.pago-desc1' | translate }}
                </span>
                <p class="mb-20">
                    {{ 'LandingPolimentes.what-do-we-do.pago-desc2' | translate }}
                </p>
                <p class="mb-20">
                    {{ 'LandingPolimentes.what-do-we-do.pago-desc3' | translate }}
                </p>
                <p class="mb-20">
                    {{ 'LandingPolimentes.what-do-we-do.pago-desc4' | translate }}
                </p>
            </div>
            <div data-aos="fade-left" data-aos-duration="3000" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center">
                <img fxFlex="60" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="95" src="/assets/img/Hacemos/Pasarela.png">
            </div>
        </div>
    </div>
</div>
