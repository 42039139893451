<div class="us__content" fxFlex="100" fxLayout="row wrap">
    <div class="history" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <p class="history__title" fxFlex="100" fxFlex.xs="90" fxFlex.sm="90" fxFlex.md="90"
            fxLayoutAlign="center center">{{ 'LandingPolimentes.us.conoce' | translate }}</p>
        <div fxFlex="70" fxFlex.xs="80" fxFlex.sm="80" fxLayout="row wrap" fxLayoutAlign="space-evenly center"
            fxLayoutAlign.xs="center center">
            <div fxFlex="48" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="center center">
                <p class="content_text" fxFlex="100" fxLayoutAlign="center center" [innerHTML]="'LandingPolimentes.us.conoce-desc' | translate">
                </p>
            </div>
            <div fxFlex="48" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="center center">
                <img class="icon_poli" fxFlex.xs="50" src="/assets/img/icon_poli.png">
            </div>
        </div>
    </div>
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center" fxHide.xs>
        <video fxFlex="100" autobuffer loop autoplay oncanplay="this.play()" playsinline controls
            controlslist="nodownload"
            src="https://landingpagespolimentes.s3.us-east-2.amazonaws.com/polimentes/nosotros.mp4" type="video/mp4">
        </video>
    </div>
    <div class="hub" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="60" fxFlex.xs="80" fxFlex.sm="80" fxFlex.xl="52" fxFlex.md="90" fxLayout="row wrap"
            fxLayoutAlign="space-between start" fxLayoutAlign.xs="center center">
            <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start center" fxHide.xs
                fxHide.sm>
                <img fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="70" src="/assets/img/us/us__flechita.png">
            </div>
            <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start center"
                fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
                <div fxFlex="100" fxLayoutAlign="start center" fxLayoutAlign.xs="center center"
                    fxLayoutAlign.sm="center center">
                    <span class="us__title_polimentes">{{ 'LandingPolimentes.us.en' | translate }}</span>
                    <img src="../../../assets/icons/text_polimentes_movil.svg">
                </div>
                <span class="us__subtitle" [innerHTML]="'LandingPolimentes.us.conoce-desc1' | translate"></span>
                <span fxFlex="100" class="text2_extre">{{ 'LandingPolimentes.us.conoce-desc2' | translate }}</span>
                <ul class="us__list" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center"
                    fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
                    <li fxFlex="100">{{ 'LandingPolimentes.us.conoce-li1' | translate }} </li>
                    <li fxFlex="100">{{ 'LandingPolimentes.us.conoce-li2' | translate }}</li>
                    <li fxFlex="100">{{ 'LandingPolimentes.us.conoce-li3' | translate }}</li>
                    <li fxFlex="100">{{ 'LandingPolimentes.us.conoce-li4' | translate }} </li>
                    <li fxFlex="100">{{ 'LandingPolimentes.us.conoce-li5' | translate }}</li>
                </ul>
                <p class="mb-20 text_inges">
                   {{ 'LandingPolimentes.us.conoce-desc3' | translate }}
                </p>
            </div>
            <div class="img_flechita_mobile" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center"
                fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center" fxHide.gt-sm>
                <img fxFlex.xs="75" fxFlex.sm="50" src="/assets/img/us/us__flechita.png">
            </div>
        </div>
    </div>
    <div class="" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="60" fxFlex.xs="80" fxFlex.sm="80" fxFlex.xl="52" fxFlex.md="90" fxLayout="row wrap"
            fxLayoutAlign="space-evenly center" fxLayoutAlign.xs="center center">
            <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center">
                <span class="us__subtitle">{{ 'LandingPolimentes.us.conoce-desc4' | translate }}</span>
                <p class="mb-20">
                   {{ 'LandingPolimentes.us.conoce-desc5' | translate }}
                </p>
            </div>
            <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center"
                fxLayoutAlign.sm="center center" fxLayoutAlign.xs="center center">
                <img fxFlex="75" fxFlex.xs="100" fxFlex.sm="70" src="/assets/img/us/tools.png">
            </div>
        </div>

    </div>
    <div class="tools__fondo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
      <div class="tools" fxFlex="60" fxFlex.xs="80" fxFlex.sm="80" fxFlex.xl="52" fxFlex.md="90" fxLayout="row wrap"
      fxLayoutAlign="center center">
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center ">
          <span fxFlex="100" class="tools__title" [innerHTML]="'LandingPolimentes.us.conoce-desc6' | translate"></span>
          <button class="tools__button" fxFlex="15" fxFlex.xs="70" fxFlex.sm="30"
              routerLink="/join-up">{{ 'LandingPolimentes.us.conoce-desc7' | translate }}</button>
          <p fxFlex="100" class="tools__slogan mb-20">
              {{ 'LandingPolimentes.us.conoce-desc8' | translate }}
          </p>
      </div>
  </div>
    </div>
</div>
