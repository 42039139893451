import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  public message: any = '';
  public title: any = '';
  public contador={
    segundos:2,
    intervalo:null,
  };

  constructor(public dialogRef: MatDialogRef<ErrorComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.title = data.title;
    this.message = data.message;
   }

  aceptar(){
    this.dialogRef.close();
  }

  ngOnInit(): void {
    //this.conteo();
  }

  conteo() {
    this.contador.intervalo = setInterval(() => {
      this.contador.segundos--;
      if (this.contador.segundos === 0) {
        clearInterval(this.contador.intervalo);
        this.aceptar();
      }
    }, 1000);
  }

}
