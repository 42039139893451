import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
@Component({
  selector: 'app-alliances-certifications',
  templateUrl: './alliances-certifications.component.html',
  styleUrls: ['./alliances-certifications.component.css']
})
export class AlliancesCertificationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    AOS.init({
      duration: 2000
    });
  }

}
