import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ErrorComponent } from 'src/app/components/message/error/error.component';
import { SuccessComponent } from 'src/app/components/message/success/success.component';
import { ServiceService } from 'src/app/services/service.service';

@Component({
  selector: 'app-join-up',
  templateUrl: './join-up.component.html',
  styleUrls: ['./join-up.component.css']
})
export class JoinUpComponent implements OnInit {
  public active: number;
  public mask = [/[1-9]/, /\d/, ' ', /\d/,  /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
  public vacantes: any = [
    {
      id: 0,
      nombre: 'LandingPolimentes.join-up.vacante.desarrollo',
      perfil: 'LandingPolimentes.join-up.vacante.desarrollo-desc',
      skills: [
        'LandingPolimentes.join-up.vacante.skill.l1',
        'LandingPolimentes.join-up.vacante.skill.l2',
        'LandingPolimentes.join-up.vacante.skill.l3',
        'LandingPolimentes.join-up.vacante.skill.l4',
        'LandingPolimentes.join-up.vacante.skill.l5',
        'LandingPolimentes.join-up.vacante.skill.l6',
      ],
      conocimientos: [
        'Conocimiento en diseño y análisis en el desarrollo de sistemas móviles (Diagramas).',
        'Desarrollo por medio de Framework Flutter.',
        'Patrones de arquitectura: MVC (obligatorio) y MVP.',
        'Manejo de sensores del dispositivo.',
        'Persistencia de datos: SQLite (obligatorio).',
        'Manejo y parseo de datos en formato JSON y XML.',
        'Consumo de servicios REST y SOAP.',
        'Diseño de interfaces (responsivas desde móvil hasta tabletas).',
        'Experiencia en el manejo de API’s.',
        'Conocimientos en el manejo GitHub.',
        'Experiencia en realización de pruebas Unitarias y de integración (sólo o en equipo).',
      ]
    },
    {
      id: 1,
      nombre: 'Desarrollador Backend',
      perfil: 'Ingeniería en Sistemas Computacionales, Ingeniería de Software, Ingeniería en Computación, Licenciatura en Informática, o carrera afín.',
      skills: [
        'LandingPolimentes.join-up.vacante.skill.l1',
        'LandingPolimentes.join-up.vacante.skill.l2',
        'LandingPolimentes.join-up.vacante.skill.l3',
        'LandingPolimentes.join-up.vacante.skill.l4',
        'LandingPolimentes.join-up.vacante.skill.l5',
        'LandingPolimentes.join-up.vacante.skill.l7',
      ],
      conocimientos: [
        'Django / Django Rest Framework.',
        'Conocimiento en Python 3.5, 3.6.',
        'Conocimiento en diseño y análisis en el desarrollo de aplicaciones web (Diagramas).',
        'Modelos Serialización OAuth2 y manejo de permisos y tipos de usuarios.',
        'Routers Views.',
        'Experiencia en integración de API’s de terceros.',
        'Dominio en POO.',
        'Experiencia en el sistema operativo Linux.',
        'Arquitectura y diseño de bases de datos relacionales y no relaciones. (MySQL (obligatorio) y MongoDB).',
        'Conocimiento básico en comando de Git (commit, pull, push).',
        'Docker, docker-compose AWS: EC2, ECS, S3, RDS, IAM.',
        'PDF Generator (deseable).',
      ]
    },
    {
      id: 2,
      nombre: 'Desarrollador Frontend',
      perfil: 'Licenciatura en Sistemas Computacionales, Ingeniería de Software, Ingeniería en Computación, Ingeniería en informática, o carrera afín.',
      skills: [
        'LandingPolimentes.join-up.vacante.skill.l1',
        'LandingPolimentes.join-up.vacante.skill.l2',
        'LandingPolimentes.join-up.vacante.skill.l3',
        'LandingPolimentes.join-up.vacante.skill.l4',
        'LandingPolimentes.join-up.vacante.skill.l5',
        'LandingPolimentes.join-up.vacante.skill.l7',
      ],
      conocimientos: [
        'PHP, JS, TS, Jquery.',
        'HTML.',
        'CSS.',
        'Creatividad en el maquetado de componentes.',
        'Dominio en POO.',
        'Servicios REST y SOAP.',
        'Manejo de información en XML/JSON por medio de peticiones HTTP.',
        'Laravel, Angular, Bootstrap, Angular Material,MDB.',
        'Conocimiento en diseño y análisis en el desarrollo de aplicaciones web (Diagramas).',
        'Manejo de sistema operativo Linux y Windows 10.',
        'Conocimiento básico en comando de Git (commit, pull, push).',
      ]
    },
    {
      id: 3,
      nombre: 'Diseñador UX/UI',
      perfil: 'Licenciatura en Diseño web, Diseño Gráfico, Diseño Experiencia de Usuario, o carrera afín.',
      skills: [
        'LandingPolimentes.join-up.vacante.skill.l1',
        'LandingPolimentes.join-up.vacante.skill.l2',
        'LandingPolimentes.join-up.vacante.skill.l3',
        'LandingPolimentes.join-up.vacante.skill.l8',
        'LandingPolimentes.join-up.vacante.skill.l5',
        'LandingPolimentes.join-up.vacante.skill.l7',
        'LandingPolimentes.join-up.vacante.skill.l9',
        'LandingPolimentes.join-up.vacante.skill.l10',
      ],
      conocimientos: [
        'HTML.',
        'CSS.',
        'Creatividad en estructuras de vistas.',
        'Adobe Illustrator (avanzado).',
        'Herramientas de prototipado Zeplin y Adobe XD.',
        'Excelente habilidad de comunicación escrita: redacción, ortografía.',
        'Creación de Wireframes bajo estándares.',
        'Metodologías ágiles.',
      ]
    },
    {
      id: 4,
      nombre: 'Diseñador Web',
      perfil: 'Licenciatura en Diseño web, Diseño Gráfico, Diseño Experiencia de Usuario, o carrera afín.',
      skills: [
        'LandingPolimentes.join-up.vacante.skill.l1',
        'LandingPolimentes.join-up.vacante.skill.l3',
        'LandingPolimentes.join-up.vacante.skill.l5',
        'LandingPolimentes.join-up.vacante.skill.l7',
        'LandingPolimentes.join-up.vacante.skill.l10',
        'LandingPolimentes.join-up.vacante.skill.l11',
      ],
      conocimientos: [
        'HTML.',
        'CSS.',
        'Creatividad en estructuras de vistas (importante).',
        'Adobe Illustrator y Adobe photoshop (avanzado).',
        'Creación de Wireframes bajo estándares.',
        'Herramientas de prototipado Zeplin y Adobe XD.',
        'Excelente habilidad de comunicación escrita: redacción, ortografía.',
      ]
    },
    {
      id: 5,
      nombre: 'Gestor de Cuentas',
      perfil: 'Licenciatura en Administración, Dirección de Empresas preferentemente con especialización en el área de Marketing.',
      skills: [
        'LandingPolimentes.join-up.vacante.skill.l12',
        'LandingPolimentes.join-up.vacante.skill.l13',
        'LandingPolimentes.join-up.vacante.skill.l14',
        'LandingPolimentes.join-up.vacante.skill.l15',
        'LandingPolimentes.join-up.vacante.skill.l16',
        'Orientación al cliente siempre buscando la mayor satisfacción.',
        'LandingPolimentes.join-up.vacante.skill.l8',
        'LandingPolimentes.join-up.vacante.skill.l5',
        'Pensamiento estratégico.',
      ],
      conocimientos: [
        'Tecnología de la nube: Gsuite principalmente/Paquetería Office.',
        'Manejo de información actualizada, tendencias, noticias, necesidades del cliente.',
        'Conocimiento del mercado a profundidad incluyendo prácticas comerciales, desafíos, objetivos y competidores.',
        'Reforzar y optimizar las relaciones con los clientes fijos de la empresa, así como el establecimiento de nuevos contactos con clientela potencial.',
        'Crear una estrategia única y personalizada para cada uno de los clientes.',
        'Generar mayor conexión entre los ejes de la estructura organizacional de la empresa y mejorar la comunicación externa e interna.',
        'Generar propuestas para diferenciación con respecto a la competencia, ganando posiciones privilegiadas con los clientes.',
        'Profundo seguimiento de la evolución de las relaciones con los clientes a través de indicadores de gestión.',
      ]
    },
    {
      id: 6,
      nombre: 'Gestor de Proyectos',
      perfil: 'Licenciatura en gestión de proyectos, Dirección de empresas',
      skills: [
        'LandingPolimentes.join-up.vacante.skill.l15',
        'LandingPolimentes.join-up.vacante.skill.l14',
        'Atención a los detalles.',
        'Gestión de equipos y liderazgo.',
        'Administración de relaciones.',
        'LandingPolimentes.join-up.vacante.skill.l13',
        'LandingPolimentes.join-up.vacante.skill.l8',
        'Habilidad de anticipar y resolver los problemas que se presenten.',
        'Habilidad para hacer balance de los riesgos que conllevan ciertas decisiones.',
        'Habilidad para trabajo bajo presión.',
        'Gestión de tiempos.',
        'Pensamiento analítico.',
      ],
      conocimientos: [
        'Gestión, priorización y automatización de tareas con el fin de cumplir las metas del proyecto.',
        'Tecnología de la nube: Gsuite principalmente.',
        'Generación y seguimiento de cronogramas.',
        'Generación de un plan de cumplimiento de los objetivos, que respete los recursos monetarios plasmados en el presupuesto y el tiempo establecido en el cual deben establecerse alcance y recursos asignados al proyecto.',
        'Análisis y seguimiento de cada paso que se da para asegurar que se están cumpliendo los objetivos establecidos en el proyecto.',
      ]
    },
    {
      id: 7,
      nombre: 'Responsable de Testeo',
      perfil: 'Licenciatura en Sistemas Computacionales, Ingeniería de Software, Ingeniería en informática, Ingeniería en tecnologías de la información, Ingeniería en sistemas de la información o carrera afín.',
      skills: [
        'Creatividad en resolución de problemas o propuestas.',
        'Autonomía (muy importante).',
        'Pensamiento estratégico.',
        'Habilidades de comunicación efectiva.',
        'Gestión del tiempo.',
        'LandingPolimentes.join-up.vacante.skill.l11',
        'Resolución de conflictos técnicos.',
        'Buena atención al detalle.',
      ],
      conocimientos: [
        'Metodologías de desarrollo de software (tradicionales y ágiles).',
        'Pruebas funcionales, validando Front y Back.',
        'Reportar y dar seguimiento a Defectos de una aplicación con evidencias.',
        'TFS, JIRA , Quality Center y/o alguna herramienta afín para la administración de pruebas.',
        'Diseño de casos y matrices de prueba.',
        'Pruebas de APIs (microservicios).',
        'Postman, Python, Django, Django RestFramework, Json,Angular, JS, TS.',
        'Control de Versiones GIT.',
      ]
    },
    {
      id: 8,
      nombre: 'Asesor de Ventas',
      perfil: 'Licenciatura en administración, comercio o afín (Pasante o titulado) Bachillerato concluido.',
      skills: [
        'Capacidad de comunicación verbal.',
        'LandingPolimentes.join-up.vacante.skill.l8',
        'Atención a clientes.',
        'LandingPolimentes.join-up.vacante.skill.l13',
        'Habilidades de negociación.',
        'Excelente presentación.',
      ],
      conocimientos: [
        'Tecnología de la nube: Gsuite principalmente.',
        'Asesoría personalizada al cliente y seguimiento.',
        'Ventas y contacto directo con clientes.',
        'Prever y anticipar las necesidades del cliente.',
        'Paquetería Office.',
        'Reporte de cumplimiento de objetivos y metas acorde a la empresa.',
      ]
    },
    {
      id: 9,
      nombre: 'Community Manager',
      perfil: 'Licenciatura en Diseño gráfico, mercadotecnia o carrera afín.',
      skills: [
        'LandingPolimentes.join-up.vacante.skill.l1',
        'Persona altamente creativa y proactiva.',
        'Capacidad de comunicación verbal.',
        'Paciencia y tolerancia a la frustración (atención a usuarios de redes sociales).',
        'Conocimientos de análisis de mercado.',
        'LandingPolimentes.join-up.vacante.skill.l3',
        'LandingPolimentes.join-up.vacante.skill.l8',
        'Autonomía.',
      ],
      conocimientos: [
        'Software para contenido visual (Adobe Ilustrator, Adobe Photoshop).',
        'Tecnología de la nube: Gsuite principalmente/Paquetería Office.',
        'Software para edición de contenido audiovisual (Adobe After Effects, Adobe Premiere).',
        'Gestión de plataformas de publicidad Facebook y Google.',
        'Excelente nivel de redacción. Gusto por la escritura editorial y creativa.',
      ]
    },
  ];
  @ViewChild('fo') myForm;
  @ViewChild('fo2') myForm2;
  public form: FormGroup;
  public formM: FormGroup;
  public validar: boolean = false;
  public base64: any;
  public base642: any;

  public showLoad: boolean = false;

  constructor(formBuilder: FormBuilder,
    public dialog: MatDialog,
    private servicio: ServiceService,
    private translate: TranslateService
  ) {
    this.translate.use(localStorage.getItem('idioma'));
  }

  ngOnInit(): void {
    this.doForm();
    this.doFormM();
  }

  doForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      vacant: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
      CV: new FormControl('', [Validators.required]),
    });
  }
  doFormM(): void {
    this.formM = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      vacant: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
      CV: new FormControl('', [Validators.required]),
    });
  }
  changeVac(id) {
    console.log(id);
    if (id == this.active) {
      this.active = null;
    } else {
      this.active = id;
    }
  }
  required(field: any) {
    // console.log(field);
    if (this.form.get(field).errors == null) {
      return false;
    } else {
      return true;
    }
  }
  submit(): void {
    console.log(this.form);
    if (this.form.invalid) {
      this.dialog.open(ErrorComponent, {
        width: '450px',
        data: {
          title: 'Error',
          message: '¡Tus datos no pudieron enviarse correctamente, intente mas tarde!'
        }
      });
    } else {
      console.log(this.form.value);
      let data = {
        "datos": {
          "name": this.form.get('name').value,
          "email": this.form.get('email').value,
          "phone_number": (this.form.get('phone').value).split(" ").join(""),
          "message": this.form.get('message').value,
          "vacancy": this.form.get('vacant').value,
          "language": 'es',
          "cv": this.base64
        }
      }
      console.log(data);
      this.showLoad = true;
      this.servicio.sendJoin(data).subscribe((res) => {
        this.showLoad = false;
        let dialogSucces = this.dialog.open(SuccessComponent, {
          width: '650px',
          data: {
            title: '',
            message: '¡Tu mensaje se envió correctamente, gracias por ponerte en contacto con nosotros!'
          }
        });
        dialogSucces.afterClosed().subscribe(res => {
          this.dialog.closeAll();
          this.myForm.resetForm();
          this.form.reset();
          this.form.get('name').setValue('');
          this.form.get('email').setValue('');
          this.form.get('phone').setValue('');
          this.form.get('message').setValue('');
          this.form.get('vacant').setValue('');
          this.form.get('cv').setValue('');
        });
      },
        (error) => {
          console.log(error);
          this.showLoad = false;
          let dialogError = this.dialog.open(ErrorComponent, {
            width: '650px',
            data: {
              title: 'Error',
              message: '¡Tus datos no pudieron enviarse correctamente, intente mas tarde!'
            }
          });
          dialogError.afterClosed().subscribe(res => {
            this.dialog.closeAll();
            this.myForm.resetForm();
            this.form.reset();
            this.form.get('name').setValue('');
            this.form.get('email').setValue('');
            this.form.get('phone').setValue('');
            this.form.get('message').setValue('');
            this.form.get('vacant').setValue('');
            this.form.get('cv').setValue('');
          });


        });
    }
  }
  submitMovil(): void {
    console.log(this.formM);
    if (this.formM.invalid) {
      this.dialog.open(ErrorComponent, {
        width: '650px',
        data: {
          title: 'Error',
          message: 'Favor de verificar su información'
        }
      });
    }
    else {
      console.log(this.formM.value);
      let data = {
        "datos": {
          "name": this.formM.get('name').value,
          "email": this.formM.get('email').value,
          "phone_number": (this.formM.get('phone').value).split(" ").join(""),
          "message": this.formM.get('message').value,
          "vacancy": this.formM.get('vacant').value,
          "language": 'es',
          "cv": this.base64
        }
      }
      this.showLoad = true;
      this.servicio.sendJoin(data).subscribe((res) => {
        this.showLoad = false;
        let dialogSucces = this.dialog.open(SuccessComponent, {
          width: '650px',
          data: {
            title: '',
            message: '¡Tu mensaje se envió correctamente, gracias por ponerte en contacto con nosotros!'
          }
        });
        console.log(res);
        dialogSucces.afterClosed().subscribe(res => {
          this.dialog.closeAll();
          this.myForm2.resetForm();
          this.formM.reset();
          this.formM.get('name').setValue('');
          this.formM.get('email').setValue('');
          this.formM.get('phone').setValue('');
          this.formM.get('message').setValue('');
          this.formM.get('vacant').setValue('');
          this.formM.get('cv').setValue('');
        });
        this.myForm2.resetForm();
        this.formM.reset();
      },
        (error) => {
          console.log(error);
          this.showLoad = false;
          let dialogError = this.dialog.open(ErrorComponent, {
            width: '650px',
            data: {
              title: 'Error',
              message: '¡Tus datos no pudieron enviarse correctamente, intente mas tarde!'
            }
          });
          dialogError.afterClosed().subscribe(res => {
            this.dialog.closeAll();
            this.myForm2.resetForm();
            this.formM.reset();
            this.formM.get('name').setValue('');
            this.formM.get('email').setValue('');
            this.formM.get('phone').setValue('');
            this.formM.get('message').setValue('');
            this.formM.get('vacant').setValue('');
            this.formM.get('cv').setValue('');
          });
          this.myForm2.resetForm();
          this.formM.reset();
        });
    }
  }
  async fileChange(event) {
    this.showLoad = true;
    let documentsError = [false, false, false];
    let fileList: FileList = event.target.files;
    console.log("FILE");
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // se verifica que sea la extensión solicitada
      console.log("Archivo", file.name);
      if (file.name.split('.')[1] == 'pdf') {
        console.log(file.name.split('.')[1] == 'pdf');
        //verificamos el tamaño
        if (file.size / 1024 / 1024 / 1024 < 1) {
          let File = file.name;
          let reader = new FileReader();
          reader.readAsDataURL(file);
          //extraemos la información
          reader.onload = (loadedEvent: any) => {
            var filebase14aux;
            console.log(reader.result);
            filebase14aux = reader.result;
            filebase14aux = filebase14aux.split(","[0])
            let filebase14 = filebase14aux[1];
            this.base64 = filebase14;
            console.log("base 64 formateada:", filebase14);
            this.showLoad = false;
          }
        } else {
          //Errores de tamaño
          this.showLoad = false;
          console.log("tamaño");;
        }
      } else {
        //Errores de formato de archivo
        this.showLoad = false;
      }
    } else {
      this.showLoad = false;
    }
  }
  async fileChange2(event) {
    this.showLoad = true;
    let documentsError = [false, false, false];
    let fileList: FileList = event.target.files;
    console.log("FILE");
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // se verifica que sea la extensión solicitada
      console.log("Archivo", file.name);
      if (file.name.split('.')[1] == 'pdf') {
        console.log(file.name.split('.')[1] == 'pdf');
        //verificamos el tamaño
        if (file.size / 1024 / 1024 / 1024 < 1) {
          let File = file.name;
          let reader = new FileReader();
          reader.readAsDataURL(file);
          //extraemos la información
          reader.onload = (loadedEvent: any) => {
            var filebase14aux;
            console.log(reader.result);
            filebase14aux = reader.result;
            filebase14aux = filebase14aux.split(","[0])
            let filebase14 = filebase14aux[1];
            this.base642 = filebase14;
            console.log("base 64 formateada:", filebase14);
            this.showLoad = false;
          }
        } else {
          //Errores de tamaño
          this.showLoad = false;
          console.log("tamaño");;
        }
      } else {
        //Errores de formato de archivo
        this.showLoad = false;
      }
    } else {
      this.showLoad = false;
    }
  }
}
